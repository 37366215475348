import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import metrics_01 from "assets/img/docs/setup/ecs/results/metrics_01.png";
import metrics_02 from "assets/img/docs/setup/ecs/results/metrics_02.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  metrics_01,
  metrics_02,
  React
};