import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import runservice1 from "assets/img/docs/setup/ecs/run-task/runservice1.png";
import runservice2 from "assets/img/docs/setup/ecs/run-task/runservice2.png";
import runservice3 from "assets/img/docs/setup/ecs/run-task/runservice3.png";
import runservice4 from "assets/img/docs/setup/ecs/run-task/runservice4.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  runservice1,
  runservice2,
  runservice3,
  runservice4,
  React
};