import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import SubSectionSeparator from "components/MdxSubSectionSeparator/subsectionSeparator.jsx";
import goImg1 from "assets/img/docs/gettingStarted/goSDK/img1.jpg";
import goImg2 from "assets/img/docs/gettingStarted/goSDK/img2.jpg";
import goImg3 from "assets/img/docs/gettingStarted/goSDK/img3.jpg";
import goImg4 from "assets/img/docs/gettingStarted/goSDK/img4.png";
import goImg5 from "assets/img/docs/gettingStarted/goSDK/img5.jpg";
import goImg6 from "assets/img/docs/gettingStarted/goSDK/img6.jpg";
import * as React from 'react';
export default {
  SectionSeparator,
  SubSectionSeparator,
  goImg1,
  goImg2,
  goImg3,
  goImg4,
  goImg5,
  goImg6,
  React
};