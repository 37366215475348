import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import runtask1 from "assets/img/docs/setup/ecs/run-task/runtask1.png";
import runtask2 from "assets/img/docs/setup/ecs/run-task/runtask2.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  runtask1,
  runtask2,
  React
};