import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import SubSectionSeparator from "components/MdxSubSectionSeparator/subsectionSeparator.jsx";
import img15 from "assets/img/docs/img15.png";
import jsImg1 from "assets/img/docs/gettingStarted/jsSDK/img1.png";
import jsImg2 from "assets/img/docs/gettingStarted/jsSDK/img2.png";
import jsImg3 from "assets/img/docs/gettingStarted/jsSDK/img3.png";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  SectionSeparator,
  SubSectionSeparator,
  img15,
  jsImg1,
  jsImg2,
  jsImg3,
  Link,
  React
};