import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import clusterbuild1 from "assets/img/docs/setup/ecs/cluster/clusterbuild1.png";
import clusterbuild2 from "assets/img/docs/setup/ecs/cluster/clusterbuild2.png";
import clusterbuild3 from "assets/img/docs/setup/ecs/cluster/clusterbuild3.png";
import clusterbuild4 from "assets/img/docs/setup/ecs/cluster/clusterbuild4.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  clusterbuild1,
  clusterbuild2,
  clusterbuild3,
  clusterbuild4,
  React
};