import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import custom_config_01 from "assets/img/docs/setup/ecs/custom_config/custom_config_01.png";
import ssm_01 from "assets/img/docs/setup/ecs/custom_config/ssm_01.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  custom_config_01,
  ssm_01,
  React
};