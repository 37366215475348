import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import SubSectionSeparator from "components/MdxSubSectionSeparator/subsectionSeparator.jsx";
import prometheusPipelineECSImg from "assets/img/docs/gettingStarted/prometheus/ecs/Prometheus_Pipeline.png";
import createClusterECSImg from "assets/img/docs/gettingStarted/prometheus/ecs/CreateCluster.png";
import availableMetricsECSImg from "assets/img/docs/gettingStarted/prometheus/ecs/AvailableMetrics.png";
import * as React from 'react';
export default {
  SectionSeparator,
  SubSectionSeparator,
  prometheusPipelineECSImg,
  createClusterECSImg,
  availableMetricsECSImg,
  React
};