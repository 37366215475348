import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import img10 from "assets/img/docs/img10.png";
import imgSSM1 from "assets/img/docs/setup/ec2/ssm/imgSSM1.png";
import imgSSM2 from "assets/img/docs/setup/ec2/ssm/imgSSM2.png";
import imgSSM3 from "assets/img/docs/setup/ec2/ssm/imgSSM3.png";
import imgSSM4 from "assets/img/docs/setup/ec2/ssm/imgSSM4.png";
import imgSSM5 from "assets/img/docs/setup/ec2/ssm/imgSSM5.png";
import imgSSM6 from "assets/img/docs/setup/ec2/ssm/imgSSM6.png";
import imgSSM7 from "assets/img/docs/setup/ec2/ssm/imgSSM7.png";
import imgSSM8 from "assets/img/docs/setup/ec2/ssm/imgSSM8.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  img10,
  imgSSM1,
  imgSSM2,
  imgSSM3,
  imgSSM4,
  imgSSM5,
  imgSSM6,
  imgSSM7,
  imgSSM8,
  React
};