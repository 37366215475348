import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import statsd_metrics_01 from "assets/img/docs/setup/ecs/results/statsd_metrics_01.png";
import statsd_metrics_02 from "assets/img/docs/setup/ecs/results/statsd_metrics_02.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  statsd_metrics_01,
  statsd_metrics_02,
  React
};