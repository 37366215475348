import integtestworkflow from "assets/img/blogs/adot-testFramework/integtestworkflow.png";
import validatorexample from "assets/img/blogs/adot-testFramework/validatorexample.png";
import validationtemplate from "assets/img/blogs/adot-testFramework/validationtemplate.png";
import validationtype from "assets/img/blogs/adot-testFramework/validationtype.png";
import yamlfile from "assets/img/blogs/adot-testFramework/yamlfile.png";
import result from "assets/img/blogs/adot-testFramework/result.png";
import vasivasireddy from "assets/img/blogs/adot-testFramework/vasivasireddy.png";
import richardto from "assets/img/blogs/adot-testFramework/richardto.png";
import * as React from 'react';
export default {
  integtestworkflow,
  validatorexample,
  validationtemplate,
  validationtype,
  yamlfile,
  result,
  vasivasireddy,
  richardto,
  React
};