import React from "react"

class SectionSeparator extends React.Component {
  render() {
    return(
        <div>
          <br />
          <hr />
          <br />
        </div>
    )
  }
}

export default SectionSeparator