import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import iam_policy_01 from "assets/img/docs/setup/ecs/iam_policy/iam_policy_01.png";
import iam_policy_02 from "assets/img/docs/setup/ecs/iam_policy/iam_policy_02.png";
import iam_policy_03 from "assets/img/docs/setup/ecs/iam_policy/iam_policy_03.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  iam_policy_01,
  iam_policy_02,
  iam_policy_03,
  React
};