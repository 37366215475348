import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import trace_01 from "assets/img/docs/setup/ecs/results/trace_01.png";
import trace_02 from "assets/img/docs/setup/ecs/results/trace_02.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  trace_01,
  trace_02,
  React
};