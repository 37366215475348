import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import img8 from "assets/img/docs/img8.png";
import img9 from "assets/img/docs/img9.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  img8,
  img9,
  React
};