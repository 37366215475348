import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import prometheusPipelineEKSImg from "assets/img/docs/gettingStarted/prometheus/eks/Prometheus_Pipeline.png";
import proxyIngestRoleEKSImg from "assets/img/docs/gettingStarted/prometheus/eks/IAMProxyIngestRole.png";
import proxyIngestRoleTrustRelationshipEKSImg from "assets/img/docs/gettingStarted/prometheus/eks/IAMProxyIngestRole_TrustRelationship.png";
import proxyIngestRolePoliciesEKSImg from "assets/img/docs/gettingStarted/prometheus/eks/IAMProxyIngestRole_Policies.png";
import * as React from 'react';
export default {
  SectionSeparator,
  prometheusPipelineEKSImg,
  proxyIngestRoleEKSImg,
  proxyIngestRoleTrustRelationshipEKSImg,
  proxyIngestRolePoliciesEKSImg,
  React
};