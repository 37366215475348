import taskdef1 from "assets/img/docs/gettingStarted/ecs-configurations/taskdef1.png";
import taskdef2 from "assets/img/docs/gettingStarted/ecs-configurations/taskdef2.png";
import taskdef3 from "assets/img/docs/gettingStarted/ecs-configurations/taskdef3.png";
import taskdef4 from "assets/img/docs/gettingStarted/ecs-configurations/taskdef4.png";
import clustersetup1 from "assets/img/docs/gettingStarted/ecs-configurations/clustersetup1.png";
import clustersetup2 from "assets/img/docs/gettingStarted/ecs-configurations/clustersetup2.png";
import clustersetup3 from "assets/img/docs/gettingStarted/ecs-configurations/clustersetup3.png";
import taskdeploy1 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy1.png";
import taskdeploy2 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy2.png";
import taskdeploy3 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy3.png";
import taskdeploy4 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy4.png";
import taskdeploy5 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy5.png";
import taskdeploy6 from "assets/img/docs/gettingStarted/ecs-configurations/taskdeploy6.png";
import * as React from 'react';
export default {
  taskdef1,
  taskdef2,
  taskdef3,
  taskdef4,
  clustersetup1,
  clustersetup2,
  clustersetup3,
  taskdeploy1,
  taskdeploy2,
  taskdeploy3,
  taskdeploy4,
  taskdeploy5,
  taskdeploy6,
  React
};