import { Link } from "gatsby";
import designImg from "assets/img/docs/gettingStarted/container-insights/ADOT-design.png";
import clusterImg from "assets/img/docs/gettingStarted/container-insights/ADOT-cluster.png";
import logEventImg from "assets/img/docs/gettingStarted/container-insights/ADOT-log-event.png";
import ADOTMetricsImg from "assets/img/docs/gettingStarted/container-insights/ADOT-metrics.png";
import performanceImg from "assets/img/docs/gettingStarted/container-insights/ADOT-performance.png";
import * as React from 'react';
export default {
  Link,
  designImg,
  clusterImg,
  logEventImg,
  ADOTMetricsImg,
  performanceImg,
  React
};