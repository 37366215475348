import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import instancemetrics01 from "assets/img/docs/setup/ecs/results/instance-metrics-01.png";
import instancemetrics02 from "assets/img/docs/setup/ecs/results/instance-metrics-02.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  instancemetrics01,
  instancemetrics02,
  React
};