import prometheuspipeline from "assets/img/blogs/migration-to-adot/prometheuspipeline.png";
import adotcollector from "assets/img/blogs/migration-to-adot/adotcollector.png";
import batchimage from "assets/img/blogs/migration-to-adot/batchimage.png";
import addonexample from "assets/img/blogs/migration-to-adot/addonexample.png";
import resultaddonexample from "assets/img/blogs/migration-to-adot/resultaddonexample.png";
import * as React from 'react';
export default {
  prometheuspipeline,
  adotcollector,
  batchimage,
  addonexample,
  resultaddonexample,
  React
};