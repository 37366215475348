import { Link } from "gatsby";
import dashboardImg from "assets/img/docs/gettingStarted/container-insights/eks-prometheus-dashboard.png";
import logsImg from "assets/img/docs/gettingStarted/container-insights/eks-prometheus-logs.png";
import metricsImg from "assets/img/docs/gettingStarted/container-insights/eks-prometheus-metrics.png";
import * as React from 'react';
export default {
  Link,
  dashboardImg,
  logsImg,
  metricsImg,
  React
};