import jaeger from "assets/img/logzio-jaeger.png";
import metrics from "assets/img/logzio-metrics.png";
import metricsandtracing from "assets/img/logzio-metricsandtracing.png";
import tokens from "assets/img/logzio-tokens.png";
import * as React from 'react';
export default {
  jaeger,
  metrics,
  metricsandtracing,
  tokens,
  React
};