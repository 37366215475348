import { Link } from "gatsby";
import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import imgLogGroup from "assets/img/docs/gettingStarted/containerInsights/log-group.png";
import imgPodMetrics from "assets/img/docs/gettingStarted/containerInsights/pod-metrics.png";
import * as React from 'react';
export default {
  Link,
  SectionSeparator,
  imgLogGroup,
  imgPodMetrics,
  React
};