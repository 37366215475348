import { Link } from "gatsby";
import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import img1 from "assets/img/docs/img1.png";
import img4 from "assets/img/docs/img4.png";
import * as React from 'react';
export default {
  Link,
  SectionSeparator,
  img1,
  img4,
  React
};