import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import task_01 from "assets/img/docs/setup/ecs/create-task-definition/instance01.png";
import task2 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition2.png";
import task3 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition3.png";
import task4 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition4.png";
import task5 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition5.png";
import task6 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition6.png";
import task_06 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition_06.png";
import task7 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition7.png";
import task8 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition8.png";
import task9 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition9.png";
import task10 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition10.png";
import task11 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition11.png";
import task12 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition12.png";
import task13 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition13.png";
import task14 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition14.png";
import task15 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition15.png";
import task16 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition16.png";
import task17 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition17.png";
import task18 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition18.png";
import task19 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition19.png";
import task20 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition20.png";
import task21 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition21.png";
import task22 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition22.png";
import task23 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition23.png";
import task24 from "assets/img/docs/setup/ecs/create-task-definition/taskdefinition24.png";
import task25 from "assets/img/docs/setup/ecs/create-task-definition/instance02.png";
import task26 from "assets/img/docs/setup/ecs/create-task-definition/instance03.png";
import task27 from "assets/img/docs/setup/ecs/create-task-definition/instance04.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  task_01,
  task2,
  task3,
  task4,
  task5,
  task6,
  task_06,
  task7,
  task8,
  task9,
  task10,
  task11,
  task12,
  task13,
  task14,
  task15,
  task16,
  task17,
  task18,
  task19,
  task20,
  task21,
  task22,
  task23,
  task24,
  task25,
  task26,
  task27,
  React
};