import ecsdatapath from "assets/img/blogs/adot-collector-using-ecs/ecsdatapath.png";
import taskdef1 from "assets/img/blogs/adot-collector-using-ecs/taskdef1.png";
import taskdef2 from "assets/img/blogs/adot-collector-using-ecs/taskdef2.png";
import taskdef3 from "assets/img/blogs/adot-collector-using-ecs/taskdef3.png";
import taskdef4 from "assets/img/blogs/adot-collector-using-ecs/taskdef4.png";
import resultamp from "assets/img/blogs/adot-collector-using-ecs/resultamp.png";
import vasivasireddy from "assets/img/blogs/adot-testFramework/vasivasireddy.png";
import richardto from "assets/img/blogs/adot-testFramework/richardto.png";
import * as React from 'react';
export default {
  ecsdatapath,
  taskdef1,
  taskdef2,
  taskdef3,
  taskdef4,
  resultamp,
  vasivasireddy,
  richardto,
  React
};