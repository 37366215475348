import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import { Link } from "gatsby";
import iam_role_01 from "assets/img/docs/setup/ecs/iam_role/iam_role_01.png";
import iam_role_02 from "assets/img/docs/setup/ecs/iam_role/iam_role_02.png";
import iam_role_03 from "assets/img/docs/setup/ecs/iam_role/iam_role_03.png";
import iam_role_04 from "assets/img/docs/setup/ecs/iam_role/iam_role_04.png";
import iam_role_05 from "assets/img/docs/setup/ecs/iam_role/iam_role_05.png";
import iam_role_06 from "assets/img/docs/setup/ecs/iam_role/iam_role_06.png";
import iam_role_07 from "assets/img/docs/setup/ecs/iam_role/iam_role_07.png";
import iam_role_08 from "assets/img/docs/setup/ecs/iam_role/iam_role_08.png";
import iam_role_09 from "assets/img/docs/setup/ecs/iam_role/iam_role_09.png";
import * as React from 'react';
export default {
  SectionSeparator,
  Link,
  iam_role_01,
  iam_role_02,
  iam_role_03,
  iam_role_04,
  iam_role_05,
  iam_role_06,
  iam_role_07,
  iam_role_08,
  iam_role_09,
  React
};