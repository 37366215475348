import SectionSeparator from "components/MdxSectionSeparator/sectionSeparator.jsx";
import img5 from "assets/img/docs/img5.png";
import img6 from "assets/img/docs/img6.png";
import img7 from "assets/img/docs/img7.png";
import * as React from 'react';
export default {
  SectionSeparator,
  img5,
  img6,
  img7,
  React
};