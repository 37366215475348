import React from "react"

class SubSectionSeparator extends React.Component {
  render() {
    return(
        <div style={{"height": "15px"}}>
        </div>
    )
  }
}

export default SubSectionSeparator